<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    top="5vh"
    width="80%"
  >
    <el-collapse v-model="active">
      <el-collapse-item :name="1" :title="$t('route.BasicInformation')">
        <el-form ref="form" :disabled="isDetail" :model="form" :rules="rules" label-position="left" label-width="auto">
          <el-row :gutter="20">
            <el-col v-if="row" :lg="8" :sm="12" :xs="24">
              <el-form-item :label="$t('title.customerID')" prop="id">
                <el-input v-model.trim="form.id" :placeholder="$t('page.inputPlaceholder')" clearable disabled />
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item :label="$t('title.customerName')" prop="customerName">
                <el-input
                  v-model="form.customerName"
                  :maxlength="20"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item :label="$t('page.Subordinate')" prop="belongPlace">
                <el-input
                  v-model="form.belongPlace"
                  :maxlength="20"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item :label="$t('page.TaxpayerIdentificationNumber')" prop="taxpayerNumber">
                <el-input
                  v-model="form.taxpayerNumber"
                  :maxlength="50"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :sm="16" :xs="24">
              <el-form-item :label="$t('title.addressInfo')" prop="customerAddress">
                <el-input
                  v-model="form.customerAddress"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  :maxlength="200"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  show-word-limit
                  type="textarea"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-collapse-item>
      <el-collapse-item :name="2" :title="$t('title.Shippingaddress')">
        <vxe-table
          :column-config="{ resizable: true }"
          :data="form.deliveryAddress"
          :max-height="520"
          :scroll-x="{ enabled: false }"
          :scroll-y="{ enabled: false }"
          :show-overflow="false"
          align="center"
          border="none"
        >
          <vxe-column>
            <template #header>
              <span>{{ $t('order.Receipt') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'receiveName' + rowIndex"
                v-model="row.receiveName"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('receiveName', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column>
            <template #header>
              <span>{{ $t('title.country') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'country' + rowIndex"
                v-model="row.country"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('country', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column>
            <template #header>
              <span>{{ $t('order.Statesd') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'state' + rowIndex"
                v-model="row.state"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('state', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column width="140">
            <template #header>
              <span>{{ $t('title.city') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'city' + rowIndex"
                v-model="row.city"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('city', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column>
            <template #header>
              <span>{{ $t('order.Address') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'address1' + rowIndex"
                v-model="row.address1"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('address1', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column :title="$t('order.address')">
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'address2' + rowIndex"
                v-model="row.address2"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('address2', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column>
            <template #header>
              <span>{{ $t('order.Zipcode') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'zipCode' + rowIndex"
                v-model="row.zipCode"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('zipCode', rowIndex)"
              />
            </template>
          </vxe-column>
          <vxe-column>
            <template #header>
              <span>{{ $t('title.phone') }}</span>
              <span style="color: #f56c6c">*</span>
            </template>
            <template #default="{row, rowIndex}">
              <el-input
                :ref="'phone' + rowIndex"
                v-model="row.phone"
                :disabled="isDetail"
                :placeholder="$t('page.inputPlaceholder')"
                clearable
                @clear="handleClearInput('phone', rowIndex)"
              />
            </template>
          </vxe-column>
        </vxe-table>
      </el-collapse-item>
    </el-collapse>
    <template #footer>
      <el-button @click="handleClose">取 消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addOrUpdateCustomerInfo } from '@/api/omsorder'
import _ from 'lodash'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: [1, 2],
      form: {
        id: '',
        customerName: '',
        belongPlace: '',
        taxpayerNumber: '',
        customerAddress: '',
        deliveryAddress: [{
          receiveName: '',
          country: '',
          state: '',
          city: '',
          address1: '',
          address2: '',
          zipCode: '',
          phone: ''
        }]
      },
      rules: {
        id: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
        ],
        customerName: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur', max: 20 }
        ],
        belongPlace: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur', max: 20 }
        ],
        taxpayerNumber: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur', max: 50 }
        ],
        customerAddress: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur', max: 200 }
        ]
      },
      submitLoading: false
    }
  },
  computed: {
    title() {
      if (this.isDetail) return this.$t('order.Detail')
      return this.row?.id ? this.$t('title.Edit') : this.$t('page.add')
    },
    submitParams() {
      const obj = {}
      const keys = Object.keys(this.form)
      keys.forEach(key => {
        obj[key] = this.form[key]
        if (typeof this.form[key] === 'string') {
          obj[key] = this.form[key]?.trim()
        }
        if (Array.isArray(this.form[key])) {
          obj[key] = this.form[key].map(item => {
            const obj = {}
            const keys = Object.keys(item)
            keys.forEach(key => {
              if (typeof item[key] === 'string') {
                obj[key] = item[key]?.trim()
              }
            })
            return obj
          })
        }
      })
      return {
        ...obj,
        deliveryAddress: obj.deliveryAddress[0]
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.row?.id) {
          const deliveryAddress = this.row.deliveryAddress ? _.cloneDeep(this.row.deliveryAddress) : []
          this.form = {
            ...this.row,
            deliveryAddress: deliveryAddress ? [deliveryAddress] : []
          }
        }
      }
    }
  },
  methods: {
    handleClearInput(val, index) {
      const ref = val.toString() + index
      this.$refs[ref].focus()
    },
    handleClose() {
      if (this.submitLoading) {
        this.$message('正在执行中，请稍后关闭')
        return
      }
      this.form = {
        id: '',
        customerName: '',
        belongPlace: '',
        taxpayerNumber: '',
        customerAddress: '',
        deliveryAddress: [{
          state: '',
          city: '',
          address1: '',
          address2: '',
          zipCode: '',
          phone: ''
        }]
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    async validate() {
      const formValidate = await this.$refs.form.validate().catch(() => {
        //
      })
      const tableValidate = this.form.deliveryAddress?.every(item => {
        return item.receiveName && item.country && item.city && item.state && item.address1 && item.zipCode && item.phone
      })
      return formValidate && tableValidate
    },
    async handleSubmit() {
      if (!this.isDetail) {
        const valid = await this.validate()
        if (valid) {
          this.submitLoading = true
          const { msg } = await addOrUpdateCustomerInfo(this.submitParams).finally(() => {
            this.submitLoading = false
          })
          this.$message.success(msg)
          this.handleClose()
          this.$emit('refresh')
        } else {
          this.$message.error('请完善信息')
        }
      } else {
        this.handleClose()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__header {
  padding: 15px 20px 10px;

  > .el-dialog__title {
    font-size: 16px;
    line-height: 16px;
  }

  > .el-dialog__headerbtn {
    top: 15px
  }
}
</style>

<template>
  <div>
    <el-form inline label-width="0">
      <el-form-item label="">
        <el-input
          v-model="form.id"
          :placeholder="$t('title.customerID')"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="">
        <el-input
          v-model="form.customerName"
          :placeholder="$t('title.customerName')"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <el-button style="margin-bottom: 22px" type="primary" @click="handleOpenAddDialog()">{{ $t('page.create') }}</el-button>
    <el-table
      ref="table"
      v-loading="queryLoading"
      :data="tableData"
      :max-height="maxHeight"
      border
      fit
    >
      <el-table-column :label="$t('title.customerID')" align="center" prop="id" />
      <el-table-column :label="$t('title.customerName')" align="center" prop="customerName" />
      <el-table-column :label="$t('page.Subordinate')" align="center" prop="belongPlace" />
      <el-table-column :label="$t('page.TaxpayerIdentificationNumber')" align="center" prop="taxpayerNumber" />
      <el-table-column :label="$t('title.addressInfo')" align="center" prop="customerAddress" />
      <el-table-column :label="$t('page.operate')" align="center">
        <template #default="{row}">
          <el-button size="small" type="text" @click="handleOpenAddDialog(row, true)">{{ $t('route.Details') }}</el-button>
          <el-button size="small" type="text" @click="handleOpenAddDialog(row)">{{ $t('page.modify') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-dialog
      :is-detail="isDetail"
      :row.sync="currentRow"
      :visible.sync="addDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import AddDialog from './AddDialog.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import { getCustomerInfo } from '@/api/omsorder'

export default {
  name: 'CustomerInformation',
  components: {
    AddDialog
  },
  data() {
    return {
      form: {
        id: '',
        customerName: ''
      },
      tableData: [],
      queryLoading: false,
      maxHeight: 520,
      currentRow: null,
      addDialogVisible: false,
      isDetail: false
    }
  },
  created() {
    this.handleQuery()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async handleQuery() {
      this.queryLoading = true
      const { datas } = await getCustomerInfo(this.form).finally(() => {
        this.queryLoading = false
      })
      this.tableData = datas
    },
    handleReset() {
      this.form = {
        id: '',
        customerName: ''
      }
      this.handleQuery()
    },
    handleOpenAddDialog(row, isDetail) {
      this.isDetail = isDetail
      this.currentRow = row
      this.addDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
